/* hero section start */
.slider-banner1, .slider-banner2 {
    width: 100%;
    min-height: 532px;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}
.home-hero-banner {
    display: block;
    width: 100%;
}
.slider-banner1 {
    background-image: url('../../assets/home-images/Clinic-Herobanner1.svg');
}
.slider-banner2 {
    background-image: url('../../assets/home-images/Clinic-Herobanner2.svg');
}
.banner-slider.carousel-indicators [data-bs-target] {
    width: 7px !important;
    height: 7px !important;
    border-radius: 100%;
}
.banenr-txt-inner-wrap {
    display: flex;
    align-items: center;
    gap: 2rem;
}
.banner-text {
    color: #FFF;
    font-style: normal;
    font-weight: 500;
}

.hero-banner-wrap .carousel-control-prev-icon, .hero-banner-wrap .carousel-control-next-icon {
    background-size: 65%;
    border: 1px solid;
    border-radius: 100px;
}
/* hero section end */

/* map section start */
.map-section {
    padding: 4rem 0 3rem;
}
.map-text-wrap {
    display: flex;
    align-items: center;
    gap: 0.6rem;
    margin-bottom: 1rem;
}
.map-text {
    font-size: 24px;
    text-align: start;
}
.map-samll-txt {
    display: block;
}
/* map section end */

/* benefit section start */
.benefit-section {
    padding: 4rem 0;
    background-color: #f3fbfb;
}
.benefit-col-wrap {
    display: flex;
    align-items: flex-start;
    text-align: start;
    gap: 3rem;
    margin-bottom: 4rem;
}
.benefit-txt {
    line-height: 25px;
    width: 93%;
}
/* benefit section end */

/* Booking Chennel section start */
.booking-chennel-section, .prevention-section {
    padding: 3rem 0;
}
.prevention-section {
    background-color: #F6F8FF;
}
.booking-chennel-heading, .prevention-heading {
    color: #000;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 128.758%;
}
.booking-chennel-text, .prevention-text {
    color: #000;
    font-size: 20px;
    font-style: normal;
    line-height: 146%;
}
.booking-chennels-main-cover, .prevention-main-cover {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.booking-chennels-cover, .prevention-cover {
    display: flex;
    align-items: center;
    gap: 1rem;
    width: 35%;
    margin-bottom: 1rem;
}
.prevention-cover {
    width: 55%;
}
.booking-chennel-img, .prevention-img {
    width: 100%;
}
.b-chennel-link {
    color: #00CC83;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    text-decoration: none;
}
.b-chennel-link:hover {
    color: #00CC83;
}
.b-chennel-link-wrap {
    text-align: end;
    width: 80%;
}
/* Booking Chennel section end */

/* journey section start */
.journey-section, .companies-section {
    padding: 3rem 0;
}
.journey-heading-wrap, .companies-heading-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    margin-bottom: 2rem;
}

.companies-img-wrap {
    max-width: 900px;
    margin: auto;
}
/* journey section end */
/* cta section start */
.cta-section {
    width: 100%;
    height: 171px;
    display: flex;
    align-items: center;
    background-image: url('../../assets/home-images/CTA1.svg');
    background-size: cover;
    background-position: 20%;
}
.cta-section.arabic {
    background-image: url('../../assets/home-images/CTA1-ar.svg');
}
.cta-text {
    font-family: 'Poppins';
}
/* cta section end */
/* choose section start */
.choose-section {
    padding: 3rem 0;
    background-color: #f3fbfb;
}
.choose-tile {
    width: 90%;
    height: 331px;
    margin: auto;
    background-color: #fff;
    text-align: center;
    padding: 2rem 3rem;
    margin-bottom: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
/* choose section end */

/* cta two section start */
.cta-two-section {
    width: 100%;
    height: 165px;
    display: flex;
    align-items: center;
    background-image: url('../../assets/home-images/CTA2-Banner.svg');
    background-size: cover;
}
.cta-two-section.arabic {
    background-image: url('../../assets/home-images/CTA2-Banner-ar.svg');
}
.cta-two-text-wrap {
    display: flex;
    align-items: center;
    gap: 3rem;
}
/* cta two section end */








@media screen and (min-width: 480px) {
    .only-mob {
        display: none;
    }
}
@media screen and (min-width: 1280px) {
    
}
@media screen and (min-width: 1600px) {
    
}
@media screen and (min-width: 1900px) {
    
}

@media screen and (max-width: 1080px) {}
@media screen and (max-width: 760px) {
    .hide-br-mb {
        display: none;
    }
    .nav-book-call-wrap.d-flex {
        display: block !important;
        text-align: center;
    }
    .journey-heading-wrap img, .form-col-wrap2, .form-col-wrap3, .companies-heading-wrap img {
        display: none;
    }
    .map-image {
        margin-top: 3rem;
    }
}
@media screen and (max-width: 480px) {
    .hide-mb {
        display: none;
    }
    .map-wrapper, .benefit-wrapper, .channel-wrapper, .cta-wrapper, .choose-wrapper, .cta-two-wrapper, .product-hero-wrap, .faq-wrapper, .pricing-wrapper, .about-hero-wrapper, .business-wrapper, .about-timeline-wrapper, .office-wrapper, .passionate-wrapper, .about-cta-wrapper {
        width: 100%;
        margin: auto;
        text-align: center;
    }
    .benefit-icon {
        margin-bottom: 2rem;
    }
    .benefit-txt {width: 100%;}
    .benefit-col-wrap {
        display: block;
        text-align: center;
        margin-bottom: 3rem;
    }
    .map-text {
        font-size: 18px;
    }
    .booking-chennels-main-cover, .prevention-main-cover {
        flex-direction: column;
    }
    .booking-chennels-cover, .prevention-cover {
        width: 100%;
        justify-content: center;
    }
    .companies-logo-img {
        display: block;
        width: 85%;
        margin: auto;
    }
    .cta-two-text-wrap {
        display: block;
    }
    .cta-two-text-wrap a {
        margin-top: 1rem;
    }
    .cta-two-section {
        height: auto;
        padding: 2rem 0;
    }
    .cta-section {
        background-position-x: left;
        height: auto;
        padding: 2rem 0;
    }
    button.navbar-toggler {
        transform: scale(0.8);
    }

}