/* ********** Contact Page CSS Start *********** */
.contact-section {
    background-color: #fafafa;
    padding: 2rem 1rem;
}
.contact-form-input-wrap input {
    background: #F4F4F4;
    border-radius: 0;
    border: none;
    color: #000;
    font-size: 14px;
    font-weight: 500;
    height: 42px;
}
.contact-form-input-wrap .form-label {
    color: #000;
    text-align: start;
    font-family: Cairo;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}
.contact-form-input-wrap .intl-tel-input input {
    height: 42px;
}
.contact-form-input-wrap .intl-tel-input .flag-dropdown {
    top: 7px;
}
.contact-form-btn {
    border-radius: 5px;
    background: #00CC83;
    width: 197px;
    height: 60px;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    font-family: 'Cairo';
    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.12);
}
.contact-choose-product-label {
    color: #011C2A;
    font-family: Cairo;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}
.contact-checkbox-wrapper {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    margin-bottom: 1rem;
}
.contact-form-button-wrap {
    text-align: center;
    margin-top: 3rem;
}
.contact-wrapper {
    width: 68%;
    margin: 2rem auto;
}
.react-tel-input .form-control{
    width: 100% !important;
    background: #F4F4F4;
    border-radius: 0;
    border: none;
    color: #000;
    font-size: 14px;
    font-weight: 500;
    height: 42px;}
@media screen and (max-width: 480px) {
    .contact-wrapper {
        width: 100%;
        margin: 1rem auto;
    }
    .contact-checkbox-wrapper {
        display: block;
    }
}
/* ********** Contact Page CSS End *********** */