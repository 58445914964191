/* footer section start */
.footer-wrapper {
    /* width: 90%; */
    height: 158px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.25rem 4rem;
    margin: auto;
}
.footer-link-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    width: 40%;
}
.footer-link {
    color: #1E2833;
    text-decoration: none;
}
.flashlead {
    font-family: 'Hurme Geometric';
}
.arabic .flashlead {
    font-family: Cairo;
    font-weight: 700;
}
/* footer section end */

@media screen and (max-width: 760px) {
    .footer-wrapper {
        height: auto;
        display: block;
        text-align: center;
    }
    .footer-link-wrap {
        margin: auto;
    }
}
@media screen and (max-width: 480px) {
    .footer-link-wrap {
        width: 100%;
        justify-content: center;
        gap: 1rem;
    }
}