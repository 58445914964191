/* header start */
.clinic-header {
    padding: 1.25rem 4rem;
}
.clinics-nav-ul {
    gap: 9%;
    margin: auto;
    text-align: center;
    width: 75%;
    justify-content: center;
}
.clinics-nav-ul .nav-link {
    color: #000;
    font-size: 16px;
    font-weight: 600;
}
.clinics-nav-ul .nav-link.active {
    color: #00CC83;
    border-bottom: 2px solid;
}
.arabic-lang {
    font-family: 'Almarai';
    font-weight: 700 !important;
}
.book-call-btn {
    border-radius: 25px;
    background: #00CC83;
    width: 130px;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
}
.book-call-btn:hover {
    color: #00CC83;
    border: 1px solid;
}
/* header end */
