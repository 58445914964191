/* Hero section start */
.product-hero-section {
    min-height: 498px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f3faf5;
    background-image: url('../../assets/product-images/first-background.webp');
    background-size: cover;
    text-align: center;
}

.product-work-section {
    padding: 3rem 0;
}
.product-hero-heading {
    font-size: 48px;
    margin-bottom: 1.5rem;
}
/* Hero section end */

/* selfbooking section start */
.selfbooking-section {
    padding: 3rem 1rem;
    text-align: center;
}
.selfbooking-heading {
    color: #040000;
    font-family: 'Cairo' !important;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 178.758%;
}
.selfbooking-inner-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    width: 85%;
    margin: auto;
}
.self-col-wrap, .prevent-col-wrap {
    width: 33.333%;
}
.self-col-img-wrap, .prevent-col-img-wrap {
    min-height: 247px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.self-toggle-img1, .self-toggle-img2, .prevent-toggle-img1, .prevent-toggle-img2 {
    position: absolute;
    z-index: 0;
    right: 30px;
    top: 41px;
}
.self-toggle-img2, .prevent-toggle-img2 {
    left: 22px;
    top: 41px;
}
.self-col-img, .prevent-col-img {
    position: relative;
    z-index: 1;
}
.self-col-img1 {
    left: 3rem;
}
.prevent-col-img1 {
    left: 35px;
    top: 4px;
}
.self-col-img2, .self-col-img3 {
    right: 3rem;
}
.prevent-col-img3 {
    right: 60px;
}
/* selfbooking section end */

/* sf reason section start */
.sf-reason-section {
    background: #F0FDF9;
    padding: 3rem 0;
}
.prevention-reason-section {
    background: #F6F8FF;
    padding: 3rem 0;
}
.sf-reason-inner-wrap {
    display: flex;
    align-items: center;
    gap: 3rem;
}
.sf-reason-icon-wrap, .prevent-reason-icon-wrap  {
    width: 55px;
    height: 52px;
    display: flex;
    justify-content: start;
    align-items: center;
    border-bottom: 1px solid #00CC83;
    margin-bottom: 1rem;
}
.prevent-reason-icon-wrap {
    border-bottom: 1px solid #2248FD;
}
.sf-reason-heading {
    margin-right: 5rem;
}
.sf-reason-col-wrap {
    width: 20%;
}
/* selfbooking section end */

/* selfbooking section start */
.book-benefit-section, .product-pricing-section {
    padding: 2rem 0;
}
.book-benefit-text {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 178.758%;
    text-align: center;
}
.book-benefit-heading, .pricing-heading {
    color: #040000;
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 178.758%;
    letter-spacing: 4.16px;
}
.book-benefit-card {
    padding: 1rem;
    background: #FFF;
    box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.05);
    max-width: 324px;
    min-height: 117px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1.5rem;
    margin-bottom: 1rem;
}
.book-benefit-card-txt {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 184%;
}
.book-benefit-card-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
}
/* selfbooking section end */

/* pricing section start */
.pricing-card-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 2rem;
}
.pricing-card {
    border-radius: 19.558px;
    border: 0.815px solid var(--Neutral-300, #EFF0F6);
    background: var(--Neutral-100, #FFF);
    box-shadow: 0px 1.63px 9.779px 0px rgba(20, 20, 43, 0.08);
    max-width: 321px;
    min-height: 700px;
    padding: 2rem 2rem;
    position: relative;
}
.pricing-card-txt {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    text-align: start;
    line-height: 178.758%;
}
.pricing-card-heading {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    text-align: start;
    line-height: 166.5%;
}
.pricing-card-txt-wrap {
    margin-bottom: 0.9rem;
}
.pricing-card-start {
    color: #3D474D;
    font-family: Cairo;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    text-align: start;
    line-height: normal;
}
.pricing-card-price {
    color: #2248FD;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    text-align: start;
    line-height: 41.542px;
}
.pricing-card-price-spn {
    font-size: 16px;
    font-weight: 400;
}
.pricing-benefit-txt {
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    text-align: start;
    line-height: 16.299px;
}
.pricing-benefit-ul {
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    text-align: start;
    line-height: 31.299px;
    list-style: none;
    padding-left: 1.6rem;
}
.arabic .pricing-benefit-ul {
    padding-right: 1.6rem;
}
.pricing-benefit-li {
    position: relative;
}
.pricing-ul-spn {
    position: absolute;
    left: -25px;
    top: 0px;
}
.arabic .pricing-ul-spn {
    right: -25px;
}
.product-pricing-btn {
    border-radius: 78.233px;
    background: var(--green, #00CC83);
    color: #fff;
    width: 100%;
    max-width: 255px;
    text-align: center;
    margin: auto;
    position: absolute;
    bottom: 3rem;
}
.product-pricing-btn:hover {
    background: var(--green, #00CC83);
    color: #fff; 
}
.pricing-second-card {
    min-height: 765px;
}
/* pricing section end */

/* accordian section start */
.product-faq-section {
    padding: 3rem 0;
}
.faq-wrapper .accordion-button {
    text-align: start;
}
.faq-wrapper .accordion-button:not(.collapsed) {
    background-color: transparent;
    color: #000;
}
.faq-wrapper .accordion-item {
    border: none;
    border-top: 1px solid #E6E6E6;
    border-bottom: 1px solid #E6E6E6;
}
.faq-wrapper .accordion-item:first-of-type {
    border-radius: 0;
}
.faq-wrapper .accordion-button {
    padding: 1rem 0;
}
/* accordian section end */











@media screen and (min-width: 1280px) {
    
}
@media screen and (min-width: 1600px) {
    
}
@media screen and (min-width: 1900px) {
    .self-col-img1 {
        left: 9rem;
    }
    .self-col-img3 {
        right: 8rem;
    }
    .prevent-col-img1 {
        left: 8rem;
    }
    .prevent-col-img3 {
        right: 9.5rem;
    }
}
@media screen and (max-width: 480px) {
    .self-toggle-img1, .self-toggle-img2, .prevent-toggle-img1, .prevent-toggle-img2 {
        display: none;
    }
    .selfbooking-inner-wrap {
        display: block;
        width: 100%;
        margin: auto;
    }
    .self-col-wrap, .prevent-col-wrap {
        width: 100%;
    }
    .self-col-img, .prevent-col-img {
        position: unset;
    }
    .self-col-img2 {
        position: relative;
        right: 3rem;
    }
    .sf-reason-inner-wrap {
        display: block;
        text-align: center;
    }
    .sf-reason-col-wrap {
        width: 100%;
    }
    .sf-reason-heading {
        margin-right: 0;
    }
    .sf-reason-icon-wrap, .prevent-reason-icon-wrap {
        justify-content: center;
        margin: auto;
        margin-bottom: 1rem;
    }
    .pricing-card-wrapper {
        flex-flow: column;
    }
    
}