/* Roboto font family start */
@font-face {
    font-family: Roboto;
    src: url('./assets/fonts/Roboto-Thin.ttf');
    font-weight: lighter;
}
@font-face {
    font-family: Roboto;
    src: url('./assets/fonts/Roboto-Regular.ttf');
    font-weight: normal;
}
@font-face {
    font-family: Roboto;
    src: url('./assets/fonts/Roboto-Bold.ttf');
    font-weight: bold;
}
@font-face {
    font-family: Roboto;
    src: url('./assets/fonts/Roboto-Black.ttf');
    font-weight: bolder;
}
/* ----------------------------------------- */
@font-face {
    font-family: Roboto;
    src: url('./assets/fonts/Roboto-Thin.ttf');
    font-weight: 100;
}
@font-face {
    font-family: Roboto;
    src: url('./assets/fonts/Roboto-Light.ttf');
    font-weight: 200;
}
@font-face {
    font-family: Roboto;
    src: url('./assets/fonts/Roboto-Light.ttf');
    font-weight: 300;
}
@font-face {
    font-family: Roboto;
    src: url('./assets/fonts/Roboto-Regular.ttf');
    font-weight: 400;
}
@font-face {
    font-family: Roboto;
    src: url('./assets/fonts/Roboto-Medium.ttf');
    font-weight: 500;
}
@font-face {
    font-family: Roboto;
    src: url('./assets/fonts/Roboto-Medium.ttf');
    font-weight: 600;
}
@font-face {
    font-family: Roboto;
    src: url('./assets/fonts/Roboto-Bold.ttf');
    font-weight: 700;
}
@font-face {
    font-family: Roboto;
    src: url('./assets/fonts/Roboto-Black.ttf');
    font-weight: 800;
}
@font-face {
    font-family: Roboto;
    src: url('./assets/fonts/Roboto-Black.ttf');
    font-weight: 900;
}
@font-face {
    font-family: Hurme Geometric;
    src: url('./assets/fonts/Hurme-Geometric-Sans.ttf');
    font-weight: 900;
}
/* Roboto font family end */




/* Poppins font family start */
@font-face {
    font-family: Poppins;
    src: url('./assets/fonts/Poppins-ExtraLight.ttf');
    font-weight: lighter;
}
@font-face {
    font-family: Poppins;
    src: url('./assets/fonts/Poppins-Regular.ttf');
    font-weight: normal;
}
@font-face {
    font-family: Poppins;
    src: url('./assets/fonts/Poppins-Bold.ttf');
    font-weight: bold;
}
@font-face {
    font-family: Poppins;
    src: url('./assets/fonts/Poppins-ExtraBold.ttf');
    font-weight: bolder;
}
/* ----------------------------------------- */
@font-face {
    font-family: Poppins;
    src: url('./assets/fonts/Poppins-Thin.ttf');
    font-weight: 100;
}
@font-face {
    font-family: Poppins;
    src: url('./assets/fonts/Poppins-ExtraLight.ttf');
    font-weight: 200;
}
@font-face {
    font-family: Poppins;
    src: url('./assets/fonts/Poppins-Light.ttf');
    font-weight: 300;
}
@font-face {
    font-family: Poppins;
    src: url('./assets/fonts/Poppins-Regular.ttf');
    font-weight: 400;
}
@font-face {
    font-family: Poppins;
    src: url('./assets/fonts/Poppins-Medium.ttf');
    font-weight: 500;
}
@font-face {
    font-family: Poppins;
    src: url('./assets/fonts/Poppins-SemiBold.ttf');
    font-weight: 600;
}
@font-face {
    font-family: Poppins;
    src: url('./assets/fonts/Poppins-Bold.ttf');
    font-weight: 700;
}
@font-face {
    font-family: Poppins;
    src: url('./assets/fonts/Poppins-ExtraBold.ttf');
    font-weight: 800;
}
@font-face {
    font-family: Poppins;
    src: url('./assets/fonts/Poppins-Black.ttf');
    font-weight: 900;
}

/* Poppins font family end */


/* Hurme Geometric font family start */
@font-face {
    font-family: Hurme Geometric;
    src: url('./assets/fonts/Hurme-Geometric-Sans.ttf');
    font-weight: 900;
}
/* Hurme Geometric font family end */


/* Almarai font family start */
@font-face {
    font-family: Almarai;
    src: url('./assets/fonts/Almarai-Regular.ttf');
    font-weight: normal;
}
@font-face {
    font-family: Almarai;
    src: url('./assets/fonts/Almarai-Bold.ttf');
    font-weight: bold;
}
@font-face {
    font-family: Almarai;
    src: url('./assets/fonts/Almarai-ExtraBold.ttf');
    font-weight: bolder;
}
/* ----------------------------------------- */
@font-face {
    font-family: Almarai;
    src: url('./assets/fonts/Almarai-Light.ttf');
    font-weight: 300;
}
@font-face {
    font-family: Almarai;
    src: url('./assets/fonts/Almarai-Regular.ttf');
    font-weight: 400;
}
@font-face {
    font-family: Almarai;
    src: url('./assets/fonts/Almarai-Regular.ttf');
    font-weight: 500;
}
@font-face {
    font-family: Almarai;
    src: url('./assets/fonts/Almarai-Regular.ttf');
    font-weight: 600;
}
@font-face {
    font-family: Almarai;
    src: url('./assets/fonts/Almarai-Bold.ttf');
    font-weight: 700;
}
@font-face {
    font-family: Almarai;
    src: url('./assets/fonts/Almarai-ExtraBold.ttf');
    font-weight: 800;
}
/* Almarai font family end */


/* Cairo font family start */
@font-face {
    font-family: Cairo;
    src: url('./assets/fonts/Cairo-ExtraLight.ttf');
    font-weight: lighter;
}
@font-face {
    font-family: Cairo;
    src: url('./assets/fonts/Cairo-Regular.ttf');
    font-weight: normal;
}
@font-face {
    font-family: Cairo;
    src: url('./assets/fonts/Cairo-Bold.ttf');
    font-weight: bold;
}
@font-face {
    font-family: Cairo;
    src: url('./assets/fonts/Cairo-ExtraBold.ttf');
    font-weight: bolder;
}
/* ----------------------------------------- */
@font-face {
    font-family: Cairo;
    src: url('./assets/fonts/Cairo-ExtraLight.ttf');
    font-weight: 200;
}
@font-face {
    font-family: Cairo;
    src: url('./assets/fonts/Cairo-Light.ttf');
    font-weight: 300;
}
@font-face {
    font-family: Cairo;
    src: url('./assets/fonts/Cairo-Regular.ttf');
    font-weight: 400;
}
@font-face {
    font-family: Cairo;
    src: url('./assets/fonts/Cairo-Medium.ttf');
    font-weight: 500;
}
@font-face {
    font-family: Cairo;
    src: url('./assets/fonts/Cairo-SemiBold.ttf');
    font-weight: 600;
}
@font-face {
    font-family: Cairo;
    src: url('./assets/fonts/Cairo-Bold.ttf');
    font-weight: 700;
}
@font-face {
    font-family: Cairo;
    src: url('./assets/fonts/Cairo-ExtraBold.ttf');
    font-weight: 800;
}
@font-face {
    font-family: Cairo;
    src: url('./assets/fonts/Cairo-Black.ttf');
    font-weight: 900;
}
/* Cairo font family end */

/* root css start */
/* * {
    text-align: start;
} */
.arabic {
    direction: rtl;
    font-family: Cairo;
}
/* .center {
    text-align: center !important;
} */
body {
    font-family: Roboto;
}
.blue {
    color: #2248fd;
}
.green {
    color: #00CC83;
}
.white {
    color: #ffffff;
}
.clinic-fs-1 {font-size: 24px;}
.clinic-fs-2 {font-size: 22px;}
.clinic-fs-3 {font-size: 20px;}
.clinic-fs-4 {font-size: 18px;}
.clinic-fs-5 {font-size: 16px;}
.clinic-fs-6 {font-size: 14px;}
.clinic-fs-7 {font-size: 12px;}
.clinic-hs-1 {font-size: 64px;}
.clinic-hs-2 {font-size: 32px;}
.clinic-hs-3 {font-size: 26px;}
.clinic-hs-4 {font-size: 24px;}
.clinic-hs-5 {font-size: 22px;}
.clinic-hs-6 {font-size: 20px;}

.choose-tile-text{min-height: 120px;}

.fw-3 {font-weight: 300;}
.fw-4 {font-weight: 400;}
.fw-5 {font-weight: 500;}
.fw-6 {font-weight: 600;}
.fw-7 {font-weight: 700;}
/* root css end */

.map-wrapper, .benefit-wrapper, .channel-wrapper, .cta-wrapper,
.choose-wrapper, .cta-two-wrapper,.product-hero-wrap, .faq-wrapper, 
.work-slider-cover, .pricing-wrapper, .about-hero-wrapper, .business-wrapper,
.about-timeline-wrapper, .office-wrapper, .passionate-wrapper, .about-cta-wrapper,
.booking-chennel-wrap, .prevention-wrap, .sf-reason-wrapper, .book-benefit-wrapper,
.product-pricing-wrapper {
    width: 82%;
    margin: auto;
}
