/* ********** About Page CSS Start *********** */
.about-hero-wrapper {
    /* max-width: 750px; */
    margin: auto;
}
.about-hero-heading {
    font-size: 40px;
    font-weight: 700;
    width: 90%;
    text-align: start;
    line-height: 133%;
}
.about-hero-img {
    width: 100%;
}
.about-hero-txt {
    line-height: 186.3%;
    letter-spacing: 1px;
}
.about-hero-section {
    background-color: #f5fbf8;
    min-height: 552px;
    display: flex;
    align-items: center;
    padding: 2rem 0;
}
.about-timeline {
    background-image: url('../../assets/about-images/about-timeline.webp');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 721px;
    padding: 2rem 0;
    padding-top: 4rem;
    margin-bottom: 2rem;
}
.about-time-heading {
    line-height: 216.3%;
}

.business-section {
    background-image: url('../../assets/about-images/large-business.webp');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: center;
    background-color: #f8fcf9;
    padding: 2rem 0;
    min-height: 472px;
    display: flex;
    align-items: center;
}
.business-heading {
    line-height: 139.3%;
}
.business-txt {
    line-height: 160.8%;
}
.office-section {
    min-height: 360px;
    display: flex;
    align-items: center;
}
.office-list {
    list-style-image: url('../../assets/about-images/location-icon.webp');
    color: #000;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 129%;
    margin: 0;
    padding-left: 1.2rem;
}
.office-list li {
    margin: 1.5rem 0;
    text-align: start;
}
.passionate-section {
    min-height: 383px;
    display: flex;
    align-items: center;
    background-image: url('../../assets/about-images/helping-business.webp');
    background-size: 100%;
}
.passion-txt {
    max-width: 378px;
}

/* team section start */
.team-section {
    padding: 2rem 0;
    background-color: #ffffff;
}
.team-row {
    max-width: 800px;
    margin: auto !important;
}
.team-heading-wrap {
    text-align: center;
}


.team-img-wrap {
    position: relative;
    margin-bottom: 10px;
    border-radius: 0px;
    overflow: hidden;
    background-color: #dddddd;
    transition: all 0.4s;
}
.team-img {
    width: 100%;
}
.team-sub-hover-layer {
    bottom: 5px;
    position: absolute;
    padding: 20px;
    text-align: start;
    display: none;
}
.Team-social-wrap {
    margin: 10px 0px;
}
.team-social-link {
    margin-right: 6px;
}
/* .team-main-hover-layer {
    height: 100vh;
    background: linear-gradient(
        180.07deg,
        rgba(255, 255, 255, 0) 0.31%,
        rgba(172, 124, 234, 0) 0.32%,
        rgba(14, 0, 33, 0.8) 99.94%
    );
    border-radius: 0px;
    position: absolute;
    width: 100%;
    bottom: -500px;
    transition: all 0.4s;
} */
.team-mem-position {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 112%;
    margin-bottom: 0px;
    color: #ffffff;
}
.team-mem-name {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 112%;
    color: #ffffff;
}
.team-img-wrap:hover {
    box-shadow: 5px 5px 0px 0px #2248fd;
}
.team-info-wrap-bot {
    margin-bottom: 2rem;
}
.team-mem-name-bot {
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 142.9%;
    margin-bottom: 0.4rem;
}
.team-mem-position-bot {
    color: #7B8191;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 142.9%;
    margin-bottom: 0.4rem;
}
/* team section end */

.about-cta-section {
    background-image: url('../../assets/about-images/about-bottom-banner.webp');
    background-size: 100%;
    background-repeat: no-repeat;
    min-height: 171px;
    display: flex;
    align-items: center;
}
.about-cta-heading {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 700;
    line-height: 140%;
    text-align: start;
}

@media screen and (min-width: 480px) {
    .only-mob {
        display: none;
    }
}
@media screen and (min-width: 1280px) {
    .about-timeline {
        background-position-y: 100%;
    }
}
@media screen and (min-width: 1600px) {
    .about-timeline {
        background-position-y: 100%;
    }
}
@media screen and (min-width: 1900px) {
    .about-timeline {
        background-size: 85%;
        background-position-y: 100%;
    }
    .business-section {
        background-size: 67%;
    }
    .business-txt, .business-heading {
        line-height: 195.3%;
    }
    .passion-txt {
        line-height: 216.3%;
    }
    .passionate-section {
        background-size: 92%;
        background-position-y: center;
        background-position-x: right;
        background-repeat: no-repeat;
    }
}

@media screen and (max-width: 1080px) {}
@media screen and (max-width: 760px) {
    .hide-br-mb {
        display: none;
    }
    .nav-book-call-wrap.d-flex {
        display: block !important;
        text-align: center;
    }
    .about-hero-heading {
        width: 100%;
        font-size: 26px;
        text-align: center;
    }
    .business-section {
        min-height: auto;
        background: #f8fcf9;
        padding: 2rem 0;
    }
    .business-heading {
        text-align: center;
    }
    .business-wrapper {
        min-height: auto;
    }
    .office-section {
        padding: 2rem 0;
    }
    .office-map {
        width: 100%;
    }
    .about-cta-section {
        background-size: cover;
        background-position-x: left;
    }

    
}
@media screen and (max-width: 480px) {
    .hide-mb {
        display: none;
    }

    /* about page start */
    .about-timeline {
        background-image: none;
    }
    .passionate-section {
        background: #f6fbfa;
    }
    .passion-heading {
        text-align: center;
    }
    .passion-txt {
        margin: auto;
    }
    .about-cta-section {
        background-image: url('../../assets/about-images/about-bottom-mb.webp');
        background-position-y: center;
    }
    .about-cta-heading {
        text-align: center;
        margin-bottom: 3rem;
    }
    /* about page end */
}


/* ********** About Page CSS End *********** */